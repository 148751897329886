import { Controller } from "stimulus"
import { multipleFileUpload } from 'fileUpload'

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    multipleFileUpload(this.fieldTarget);
    this.fieldTarget.remove()
  }
}
