// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"
import "controllers"

const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)

Rails.start()
Turbolinks.start()

import 'stylesheets/application.sass'

var WebFont = require('webfontloader');

WebFont.load({
  google: {
    families: ['Staatliches', 'Open Sans']
  }
});
