import { Controller } from "stimulus"
import Quill from 'quill'

export default class extends Controller {
  static targets = [ "container" ]

  connect() {
    this.quillInit();
  }

  quillInit() {
    this.containerTargets.forEach(containerTarget => {
      const quill = new Quill(containerTarget, this.quillOption);
      const textareaElement = containerTarget.nextElementSibling;

      if (textareaElement.type !== 'textarea') {
        console.warn('You have to put <textarea> after <div data-target="quill-editor.container">')
        return;
      }

      if (textareaElement.value !== '') {
        quill.setContents(JSON.parse(textareaElement.value)['ops']);
      }

      quill.on('text-change', function() {
        let delta = quill.getContents();
        textareaElement.value = JSON.stringify(delta);
      })
    })

    // Quill toolbar hijacks tab order. this step overrides toolbar tab position

    var tabHideEls = document.querySelectorAll(".ql-toolbar .ql-picker-label");
    tabHideEls.forEach(function(item) { item.setAttribute("tabindex", -1); });
  }

  get quillOption() {
    return {
      modules: {
        toolbar: this.toolbarOption
      },
      placeholder: 'Type something here...',
      readOnly: false,
      theme: 'snow'
    }
  }

  get toolbarOption() {
    return {
      container: [ // custom dropdown
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],    // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],          // toggled buttons
        ['link']
      ]
    }
  }

}
