import { Controller } from "stimulus"
import mapboxgl from '!mapbox-gl'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "container" ]

  connect() {
    mapboxgl.accessToken = this.containerTarget.dataset.key

    const map = new mapboxgl.Map({
      container: this.containerTarget, // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: [-120.5, 43.8], // starting position [lng, lat]
      zoom: 5 // starting zoom

    });
    map.on('load', () => {
      // map.addSource('pushpins', {
      //   type: 'geojson',
      //   // Use a URL for the value for the `data` property.
      //   data: this.containerTarget.dataset.pins
      //   });
      map.scrollZoom.disable();
      map.addControl(new mapboxgl.NavigationControl());
      Rails.ajax({
        type: 'get',
        url: this.containerTarget.dataset.pins + '.json',
        data: '',
        success: function(data) {
          var coords = data.features.map(f => f.geometry.coordinates);
          data.features.forEach(f => {
            const popup = new mapboxgl.Popup({ offset: 25, closeButton: false }).setText(f.properties.description);
            return new mapboxgl.Marker({color: f.properties['marker-color']}).setLngLat(f.geometry.coordinates).setPopup(popup).addTo(map)
          });
          const bounds = coords.reduce(function(bounds, coord) {
            return bounds.extend(coord);
          }, new mapboxgl.LngLatBounds(coords[0], coords[0]));
          map.fitBounds(bounds, {
            padding: 50, animate: false, maxZoom: 14
          });
        },
        error: function() {}
      });
    });

  }
}
