import { Controller } from "stimulus"
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.fieldTargets.forEach(field => {
      const element = field
      flatpickr(element, this.flatPickrOptions)
    })
  }

  get flatPickrOptions() {
    return {
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      altInput: true,
      altFormat: 'F j, Y - H:i',
      onOpen: function(selectedDates, dateStr, instance) {
        if (dateStr == '') {
          instance.setDate(new Date());
        }
      }
    }
  }
}
