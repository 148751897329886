import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    // See if a modal is already present
    var photo_modal = document.getElementById('photo-modal');
    var modal_content = document.getElementById('modal-content');
    var modal_caption = document.getElementById('caption');
      if (!photo_modal) {
        photo_modal = document.createElement('div');
        photo_modal.setAttribute("id", "photo-modal");
        document.body.appendChild(photo_modal);
      }
      if (!modal_content) {
        modal_content = document.createElement('div');
        modal_content.setAttribute("id", "modal-content");
        photo_modal.appendChild(modal_content);
      }
      if (!modal_caption) {
        modal_caption = document.createElement('div');
        modal_caption.setAttribute("id", "caption");
        photo_modal.appendChild(modal_caption);
      }
      photo_modal.onclick = function() {
        photo_modal.style.display = 'none';
      }
    // then you can do: const modal = document.getElementById('photo-modal')
  }

  embiggen(element) {
    const photo_modal = document.getElementById('photo-modal');
    const modal_content = document.getElementById('modal-content');
    const modal_caption = document.getElementById('caption');
    modal_content.style.backgroundImage = `url('${this.element.dataset.large_url}')`;
    modal_caption.innerHTML = this.element.dataset.caption;
    photo_modal.style.display = 'block';

    // Get modal element and set css background image to match data attribute of clicked element(this.element.data-large-url).
    // Check out stimulusjs values and see if that can fit this application.
  }
}
