import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'toggle', 'burger']

  connect() {

    console.log('Nav connected')
  }

  toggle() {
    this.toggleTarget.classList.toggle('is-active')
    this.burgerTarget.classList.toggle('is-active')
  }
}
